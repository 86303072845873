@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    background-color: #0D0D0D;
}

body,
p,
a,
* {
    font-family: 'Outfit', sans-serif;
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: #CBCBCB;
    font-size: 18px;
}

ul {
    padding: 0;
    margin: 0;
}

h1,
h2,
h3 {
    font-family: 'Offside', cursive;
    margin: 0;
    padding: 0;

}

h2 {
    font-size: 64px;
    line-height: 1;
    color: #FEFFB1;
    text-transform: uppercase;
    text-shadow: 0 0pt 6pt #d8ba22;
}

.container {
    width: 90%;
    margin: 0 auto;
    padding: 0 3%;
    box-sizing: border-box;
}

.textcenter {
    text-align: center;
}

.flexalign .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.menu {
    margin-right: auto;
    padding-left: 6%;
}

ul.soclicons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #363636;
}

ul.soclicons li {
    padding: 0 5px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.soclicons li a img {
    width: 25px;
}

.rightdiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.soclicons li a {
    line-height: 0;
    transition: all 0.5s ease;
    position: relative;
}

ul.soclicons li a:hover {
    transform: scale3d(1.2, 1.2, 1.2);
}

ul.navmenu a {
    margin-right: 70px;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 1.2;
    transition: all 0.5s ease;
}

ul.navmenu a:hover,
ul.linnav a:hover {
    opacity: 60%;
}

ul.navmenu a.active,
ul.linnav a.active {
    color: #2AD401;
}

.headbtn {
    filter: drop-shadow(0px 0px 5px #96FE99);
    box-shadow: inset 0 0 5px #96FE99;
    border: 1px solid #96FE99;
}

.btn {
    background: #161616;
    padding: 9px 28px;
    border-radius: 50px;
    font-size: 16px;
    color: #2AD401;
    transition: all 0.5s ease;
}

.headbtn:hover {
    color: #96FE99;
}

.headbtn:focus {
    opacity: 80%;
}

a.logodiv {
    width: 140px;
    line-height: 0;
}

a.logodiv img {
    width: 100%;
}

header.head {
    padding: 12px 0;
    border-bottom: 1px solid #161616;
    background: rgb(22 22 22 / 70%);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    position: absolute;
    width: 100%;
    z-index: 4;
}

.primarybtn {
    display: table;
    margin: 25px auto 0;
    background: #2AD401;
    color: #1A1A1A;
    font-size: 24px;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
}

.primarybtn:hover {
    background: #96FE99;

}

.primarybtn:focus {
    background-color: rgb(43 212 1 / 80%);

}

.mainbanner .container .topcontent {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.mainbanner {
    padding: 6% 0 9%;
    background: url(Assets/images/headbg.png);
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 3;
}

.leftimg {
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: -1;
}

.rightimg {
    position: absolute;
    right: 0;
    bottom: -5px;
    z-index: -1;
}

.mainbanner:after {
    content: '';
    background: url(Assets/images/bambooright.png);
    width: 500px;
    height: 100%;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    bottom: 0;
    z-index: -2;
    right: 0;
}

.mainbanner::before {
    content: '';
    background: url(Assets/images/bambooleft.png);
    width: 500px;
    height: 100%;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    bottom: 0;
    z-index: -2;
    left: 0;
}

.gloww {
    animation: gloww 0.4s ease-in-out infinite alternate;

}

@keyframes gloww {
    from {
        opacity: 60%;
    }

    to {
        opacity: 100%;
    }
}

.marquee {
    height: 40px;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #1D1B1B;
}

.marquee div {
    display: flex;
    position: absolute;
    overflow: hidden;
    animation: marquee 15s linear infinite;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.marquee span {
    float: left;
    width: max-content;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #707070;
    LINE-HEIGHT: 0.9;
    color: #2AD401;
}

.marquee span:last-child {
    border-right: 0;
}

@keyframes marquee {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

.rightsect {
    width: 50%;
    flex: 0 0 50%;
    padding-left: 5%;
    box-sizing: border-box;
}

.leftsect {
    width: 50%;
    flex: 0 0 50%;
}

.abutsect {
    PADDING: 4% 0;
}

ul.iconsect {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

ul.iconsect li {
    width: 50%;
    list-style: none;
    padding-right: 3%;
    box-sizing: border-box;
    margin: 18px 0;
}

ul.iconsect li img {
    margin-bottom: 10px;
}

p {
    margin-bottom: 24px;
}

h2 {
    margin-bottom: 40px;
}

ul.iconsect li p {
    margin: 0;
}

ul.iconsect li:nth-child(2n) {
    padding-right: 0;
}

.marslider1,
.marslider2 {
    position: relative;
    height: 285px;
    overflow: hidden;

}

/* .slidess {
    display: flex;
    position: relative;
    height: 100%;
    width: auto;
    
  }   */
/* .marslider2 .slidess2 {
    display: flex;
    position: absolute;
    overflow: hidden;
    animation: marquee2 15s linear infinite;
    align-items: center;
    justify-content: center;
    height: 100%;
  }  
@keyframes marquee {
    0% {transform: translateX(100%);}
    100% {transform: translateX(-3000px);}
  }

  @keyframes marquee2 {
    0% { right: 0; }
    100% { right: -100%; }
  } */

.marslider .slidess img {
    margin: 15px 45px;
    border-radius: 32px;

}

.textconet {
    width: 800px;
    margin: 0 auto;
    padding: 0px 1%;
    box-sizing: border-box;
    margin-bottom: 35px;
}

.paddpost {
    padding: 3% 0;
    position: relative;
}

.textconet img {
    position: absolute;
    right: 45px;
    top: -40px;
    z-index: -1;
    animation: gloww 0.4s ease-in-out infinite alternate;
}

.raritsect.paddpost .textconet img {
    left: 45px;
    right: initial;
    top: 88px;
}

.maindiv {
    overflow: hidden;
}

img.fullwth {
    width: 100%;
}

.leftsect img {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    display: table;
}

.leftsect {
    position: relative;
}

.imgbef {
    position: relative;
}

.imgbef img.bfreimg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -30px;
    left: 0;
    margin: auto;
    max-width: 100%;
}

/* 
.imgbef:before {
    content: '';
    width: 786px;
    height: 955px;
    background: url(Assets/images/bambooframe.png);
    position: absolute;
    background-size: cover;
    top: 4px;
    right: -31px;
    bottom: 0;
    margin: auto;
    left: 0;
} */
.roadmp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.rdcont {
    width: 48%;
    flex: 0 0 48%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.middleline {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 350px;
    overflow: hidden;
}

img.middleimg {
    width: auto;
    height: max-content;
}

.leftcontent.rdcont {
    text-align: right;
    justify-content: flex-end;
}

.rdcont h4 {
    font-size: 24px;
    text-transform: uppercase;
    color: #CBCBCB;
    font-weight: bold;
    margin-bottom: 10px;
}

.roadmp.active .rdcont h4 {
    color: #2AD401;
}

.rodcont {
    width: 60%;
    flex: 0 0 60%;
}

.rightcontent.rdcont {
    top: 170px;
}

.middleline img {
    margin-top: -16px;
}

.rleftimg,
.rrightimg {
    width: 230px;
    flex: 0 0 230px;
}

.leftcontent.rdcont .rodcont {
    padding-right: 4%;
}

.rightcontent.rdcont .rodcont {
    padding-left: 4%;
}

.roadmap img {
    top: -200px;
}

.roadmap img.leftefimg {
    top: 17px;
    right: inherit;
    left: 270px;
    animation: glowwbg 0.4s ease-in-out infinite alternate;
}

.roadmp {
    position: relative;
}

.roadmp img.leftefimg {
    position: absolute;
    left: initial;
    right: 0;
    top: -170px;
    z-index: -1;
    animation: glowwbg 0.4s ease-in-out infinite alternate;
}

@keyframes glowwbg {
    from {
        opacity: 30%;
    }

    to {
        opacity: 40%;
    }
}

.roadmap img.rightefimg {
    position: absolute;
    left: 0;
    width: 15%;
    top: -170px;
    animation: glowwbg 0.4s ease-in-out infinite alternate;

}


.roadmap img.botmleftimg {
    position: absolute;
    bottom: -25px;
    top: initial;
    right: 315px;
    width: 15%;
    animation: glowwbg 0.4s ease-in-out infinite alternate;

}

.roadmap {
    padding-bottom: 12%;
}

.teamsbox img {
    width: 100%;
}

.teamsect .container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.teamsbox {
    width: 33%;
    flex: 0 0 33%;
    text-align: center;
    padding: 0 7%;
    box-sizing: border-box;
}

.teamsect {
    background: #1D1B1B;
    padding: 5% 0;
}

.teamsect img.leftefimg {
    left: 150px;
    z-index: 9;
    top: -300px;
}

.teamsbox h3 {
    font-size: 24px;
    color: #fff;
    margin-bottom: 8px;
}

.teamsbox p {
    margin-bottom: 15px;
}

.faqmain.paddpost {
    padding-top: 7%;
    padding-bottom: 7%;
}

.faqanswer {
    display: none;
    border-radius: 0;
    border-top: 1px solid #363636;
    margin-bottom: 16px;
    margin-top: 20px;
    padding-top: 20px;
}

.faqbx {
    border: 1px solid #363636;
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 10px 18px;
    transition: all 0.5s ease;
}

.faqbx:hover {
    background-color: #1D1B1B;
}

.faqques {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.faqbx:hover .imgfa {
    background: #363636;
}

.faqques .imgfa {
    background: #1D1B1B;
    padding: 20px;
    border-radius: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faqbx.active .faqanswer {
    display: block;
}

.faqbx .faqanswer {
    transition: all 0.5s ease;
}

.faqbx.active .imgfa img {
    transform: rotate(180deg);
}

.faqmain img.leftefimg {
    top: 10px;
}

footer .container {
    display: flex;
    justify-content: space-between;
}

.fleftconte {
    width: 40%;
    box-sizing: border-box;
}

.flinks {
    margin-left: auto;
    width: 15%;
    flex: 0 0 15%;
}

.fleftconte img {
    margin-bottom: 10px;
}

footer {
    background: #161616;
    padding: 5% 0;
}

.fleftconte p:last-child {
    margin: 0;
}

footer h3 {
    font-size: 24px;
    margin-bottom: 30px;
}

ul.linnav {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

ul.linnav li {
    margin-bottom: 8px;
    width: 50%;
    flex: 0 0 50%;
}

.fsociallinks ul.soclicons {
    padding: 0;
    border: 0;
    justify-content: flex-start;
}

.fsociallinks ul.soclicons li {
    padding-left: 0;
    padding-right: 10px;
}

.fsociallinks ul.soclicons li a img {
    width: 35px;
}

.rdcont .rodcont {
    position: relative;
    bottom: -50%;
    overflow: hidden;
    transition: all 0.5s ease;
    opacity: 0.5;
}

.roadmp.active .rdcont .rodcont {
    bottom: 0;
    opacity: 1;
}

.rdcont {
    position: relative;
    overflow: hidden;
}

.roadmp.active .rleftimg,
.roadmp.active .rrightimg {
    opacity: 1;
}

.middleline,
.rleftimg,
.rrightimg {
    opacity: 0.5;
    transition: all 0.5s ease-out;

}

.roadmp.active .middleline {
    opacity: 1;
}

.mintmain {
    padding: 9% 0;
    text-align: center;
    background: url(Assets/images/mintbg.png);
    background-size: cover;
    background-position: center;
}

.mintbx img {
    margin-bottom: 22px;
}

.mintbx a.btn {
    margin-bottom: 31px;
}

.mintbx a:last-child {
    color: #2ad401;
    text-decoration: underline;

}

.textm {
    margin-bottom: 20px;
    color: #2ad401;
    font-weight: bold;
    font-size: 24px;
}

.mintmain:before {
    content: '';
    left: 0;
    background: url(Assets/images/mj1.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 280px;
    height: 280px;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    animation: gloww 0.4s ease-in-out infinite alternate;
}

.mintmain:after {
    content: '';
    right: 0;
    background: url(Assets/images/mj-1.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 280px;
    height: 280px;
    position: absolute;
    bottom: -50px;
    margin: auto;
    animation: gloww 0.4s ease-in-out infinite alternate;
}

.number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    margin: 0 auto 20px;
}

.number span {
    background: #1D1B1B;
    border: 1px solid #363636;
    font-size: 16px;
    padding: 9px 15px;
    border-radius: 12px;
    font-weight: bold;
    color: #2AD401;
    cursor: pointer;
}

.number input {
    margin: 0 12px;
    padding: 8px;
    border-radius: 12px;
    border: 1px solid #363636;
    background: #1D1B1B;
    width: 110px;
    box-sizing: border-box;
    text-align: center;
}

.number input:active,
.number input:focus {
    border-color: #2AD401;
}

.number span:hover,
.number span:focus {
    background: #363636;
    border: 1px solid #1d1d1d;
}

button.btn {
    border: 0;
    margin-bottom: 25px;
    cursor: pointer;
}

.mennu {
    background: transparent;
    border: 0;
    display: none;
}

.iner-head {
    display: none;
}

.logoss {
    display: flex;
    flex-wrap: wrap;
}

.asssonn {
    width: 20%;
    flex: 0 0 20%;
}

.logoss a {
    width: 20%;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.logssec .logoss {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

}

.logssec {
    text-align: center;
    padding: 4% 0;
    background: #1d1b1b;
}

.logssec .logoss a {
    margin: 0 35px;
}

.logoss a img {
    width: 100%;
}

@media all and (max-width: 767px) and (min-width: 320px) {
    .mintbx img {
        width: 100%;
    }

    .mint {
        margin-top: 88px;
    }

    .mintmain:before {
        bottom: initial;
        left: -36px;
        z-index: -1;
    }

    .mintmain:after,
    .mintmain:before {
        display: none;
    }

    .mintmain {
        text-align: center;
    }

    .mintmain button {
        margin: 0 auto 20px;
    }

    .iner-head {
        height: 100%;
        width: 80%;
        background: #0d0d0d;
        padding: 85px 35px 35px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .iner-head ul.soclicons {
        margin-top: auto;
        border-right: 0;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .iner-head a.logodiv {
        display: block;
    }

    .iner-head .menu {
        display: block;
        padding: 0;
    }

    ul.navmenu {
        display: flex;
        flex-direction: column;
        margin-top: 27px;
    }

    .iner-head ul.navmenu a {
        margin-bottom: 20px;
    }

    .mobile-menu {
        position: fixed;
        left: -100%;
        width: 100%;
        height: 100%;
        z-index: 9;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        background: hsla(0, 0%, 9%, .7);
        border-right: 1px solid #161616;
        transition: all 0.5s ease;

    }

    .mobile-menu.open-menu {
        left: 0;
    }

    .mennu {
        display: block;
    }

    a.logodiv {
        display: none;
    }

    header ul.soclicons {
        display: none;
    }

    .menu {
        display: none;
    }

    img.gloww {
        width: 80%;
    }

    img.gloww+img {
        width: 90%;
    }

    .mainbanner:after,
    .mainbanner:before {
        display: none;
    }

    .leftimg {
        width: 100%;
        position: relative;
        bottom: 0;
        line-height: 0;
        margin-top: 40px;
    }

    .leftimg img {
        width: 100%;
    }

    .rightimg {
        display: none;
    }

    .mainbanner {
        padding-top: 22%;
        padding-bottom: 0px;
    }

    .primarybtn {
        margin: 0;
    }

    body,
    p,
    a,
    * {
        font-size: 16px;
    }

    .rightsect {
        width: 100%;
        flex: 0 0 100%;
    }

    h2 {
        margin-bottom: 18px;
        font-size: 50px;
        line-height: 1.2;
    }

    .leftsect {
        width: 100%;
        flex: 0 0 100%;
    }

    .abutsect {
        padding-top: 27%;
    }

    .imgbef img.bfreimg {
        right: -12px;
        top: 8px;
    }

    .imgbef {
        margin-bottom: 86px;
    }

    ul.iconsect li {
        width: 100%;
        padding: 0;
        margin: 15px 0;
    }

    .textconet img {
        display: none;
    }

    .textconet {
        width: 92%;
    }

    .marslider1 .slidess img,
    .marslider2 .slidess2 img {
        margin: 15px;
        width: 145px;
    }

    .marslider1,
    .marslider2 {
        height: 170px;
    }

    .teamsbox {
        width: 100%;
        flex: 0 0 100%;
    }

    .fleftconte img {
        width: 170px;
    }

    .fleftconte {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 30px;
    }

    footer .container {
        flex-wrap: wrap;
    }

    .flinks {
        margin: 0;
        width: 100%;
        flex: 0 0 100%;
    }

    .rdcont {
        width: 98%;
        flex: 0 0 98%;
        margin-bottom: 50px;
        overflow: initial;
    }

    .middleline {
        position: absolute;
        left: 0;
        width: 1%;
        height: 100%;
        flex: 0 0 1%;
    }

    .roadmp .rdcont .rodcont {
        width: 100%;
        flex: 0 0 100%;
        padding: 0 0 0;
    }

    .roadmp {
        padding-left: 8%;
    }

    .leftcontent.rdcont {
        text-align: left;
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }

    .rleftimg,
    .rrightimg {
        width: 100%;
        flex: 0 0 100%;
        position: relative;
        left: -23px;
    }

    .rleftimg img,
    .rrightimg img {
        width: 85px;
    }

    .teamsbox img {
        width: 100%;
    }

    .rightcontent.rdcont {
        top: 0;
    }

    .rleftimg {
        order: -1;
    }

    .rleftimg img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

@media all and (max-width: 1300px) and (min-width: 768px) {
    ul.navmenu a {
        margin-right: 12px;
        font-size: 14px;
    }

    .mintbx {
        margin-top: 55px;
        position: relative;
        z-index: 9;
    }

    .mintmain {
        position: relative;
    }

    .menu {
        flex: 0 0 49%;
        width: 49%;
        padding-left: 4%;
    }

    .rightdiv {
        flex: 0 0 29%;
        width: 29%;
    }

    ul.soclicons {
        padding-right: 10px;
        padding-left: 10px;
    }

    a.btn.headbtn {
        padding: 6px 10px;
        font-size: 15px;
    }

    a.logodiv {
        flex: 0 0 18%;
        width: 18%;
    }

    ul.soclicons li {
        padding: 0 3px;
    }

    ul.soclicons {
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 10px;
    }

    ul.soclicons li a img {
        width: 20px;
    }

    img.gloww {
        width: 175px;
    }

    .mainbanner::before,
    .mainbanner::after {
        width: 220px;
    }

    .leftimg,
    .rightimg {
        width: 280px;
    }

    .leftimg img,
    .rightimg img {
        width: 100%;
    }

    img.gloww+img {
        width: 225px;
    }

    .mainbanner {
        padding: 10% 0;
    }

    .mainbanner .container .topcontent {
        padding: 0 25%;
    }

    .topcontent .primarybtn {
        margin-top: 0;
    }

    .textcenter {
        width: 80%;
    }

    .primarybtn {
        font-size: 20px;
        padding: 9px 20px;
    }

    p {
        font-size: 16px;
    }

    body,
    p,
    a,
    * {
        font-size: 16px;
    }

    h2 {
        font-size: 31px;
    }

    h2 {
        margin-bottom: 18px;
    }

    .abutsect.flexalign .container {
        flex-direction: column;
        justify-content: flex-start;
    }

    .abutsect {
        padding-top: 13%;
        padding-bottom: 9%;
    }

    .rightsect {
        width: 100%;
        flex: 0 0 100%;
        margin-top: 90px;
    }

    .leftsect {
        margin-right: auto;
    }

    .imgbef img.bfreimg {
        right: -13px;
        top: 14px;
    }

    .marslider1,
    .marslider2 {
        height: 160px;
    }

    .textconet img {
        width: 135px;
    }

    .marslider1 .slidess img,
    .marslider2 .slidess2 img {
        margin: 15px;
        width: 140px;
    }

    .raritsect.paddpost .textconet img {
        top: 0;
    }

    .roadmap img {
        top: 0;
    }

    .rdcont h4 {
        font-size: 20px;
    }

    .rleftimg,
    .rrightimg {
        width: 35%;
        flex: 0 0 35%;
    }

    .rleftimg img,
    .rrightimg img {
        width: 100%;
    }

    .middleline {
        height: 400px;
        width: 1%;
    }

    .teamsect img.leftefimg {
        top: -225px;
        left: 27px;
    }

    .roadmap img.botmleftimg {
        right: 60px;
        bottom: 0;
    }

    .teamsbox {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 4%;
    }

    .teamsbox img {
        width: 100%;
    }

    .faqmain img.leftefimg {
        top: 56px;
        right: 0;
    }

    .faqques .imgfa {
        padding: 14px;
    }

    .fleftconte img {
        width: 155px;
    }

    .flinks {
        width: 30%;
        flex: 0 0 30%;
    }
}

@media all and (max-width: 1600px) and (min-width: 1301px) {
    h2 {
        font-size: 58px;
    }

    .imgbef img.bfreimg {
        right: -25px;
        top: 18px;
    }

    .middleline {
        width: 1%;
        flex: 0 0 1%;
    }

    .leftcontent.rdcont .rodcont {
        padding-right: 3%;
    }

    .rightcontent.rdcont .rodcont {
        padding-left: 3%;
    }

    .rleftimg,
    .rrightimg {
        width: 30%;
        flex: 0 0 30%;
    }

    .rdcont .rodcont {
        flex: 0 0 65%;
    }

    .rleftimg img,
    .rrightimg img {
        width: 100%;
    }

    .leftimg,
    .rightimg {
        width: 37%;
    }

    .rightimg img,
    .leftimg img {
        width: 100%;
    }

    .mainbanner img.gloww+img {
        width: 435px;
    }

    .mainbanner img.gloww {
        width: 350px;
    }

    .mainbanner:after,
    .mainbanner:before {
        width: 300px;
    }

    .textconet img {
        width: 235px;
    }

}